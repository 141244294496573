uniform sampler2D tDepth;
uniform sampler2D tWaterDUDVTexture;
uniform sampler2D tWaterFoamTexture;
uniform sampler2D tWaterSeemlessTexture;

uniform float uTime;

uniform vec4 uDepthGradientShallow;
uniform vec4 uDepthGradientDeep;
uniform float uDepthMaxDistance;

varying vec2 vUv;
varying float vElevation;

uniform float cameraNear;
uniform float cameraFar;
uniform vec2 resolution;

//	Classic Perlin 2D Noise 
//	by Stefan Gustavson
//
vec4 permute(vec4 x)
{
    return mod(((x*34.0)+1.0)*x, 289.0);
}

vec2 fade(vec2 t)
{
    return t*t*t*(t*(t*6.0-15.0)+10.0);
}

float cnoise(vec2 P)
{
    vec4 Pi = floor(P.xyxy) + vec4(0.0, 0.0, 1.0, 1.0);
    vec4 Pf = fract(P.xyxy) - vec4(0.0, 0.0, 1.0, 1.0);
    Pi = mod(Pi, 289.0); // To avoid truncation effects in permutation
    vec4 ix = Pi.xzxz;
    vec4 iy = Pi.yyww;
    vec4 fx = Pf.xzxz;
    vec4 fy = Pf.yyww;
    vec4 i = permute(permute(ix) + iy);
    vec4 gx = 2.0 * fract(i * 0.0243902439) - 1.0; // 1/41 = 0.024...
    vec4 gy = abs(gx) - 0.5;
    vec4 tx = floor(gx + 0.5);
    gx = gx - tx;
    vec2 g00 = vec2(gx.x,gy.x);
    vec2 g10 = vec2(gx.y,gy.y);
    vec2 g01 = vec2(gx.z,gy.z);
    vec2 g11 = vec2(gx.w,gy.w);
    vec4 norm = 1.79284291400159 - 0.85373472095314 * vec4(dot(g00, g00), dot(g01, g01), dot(g10, g10), dot(g11, g11));
    g00 *= norm.x;
    g01 *= norm.y;
    g10 *= norm.z;
    g11 *= norm.w;
    float n00 = dot(g00, vec2(fx.x, fy.x));
    float n10 = dot(g10, vec2(fx.y, fy.y));
    float n01 = dot(g01, vec2(fx.z, fy.z));
    float n11 = dot(g11, vec2(fx.w, fy.w));
    vec2 fade_xy = fade(Pf.xy);
    vec2 n_x = mix(vec2(n00, n01), vec2(n10, n11), fade_xy.x);
    float n_xy = mix(n_x.x, n_x.y, fade_xy.y);
    return 2.3 * n_xy;
}

float getDepth( const in vec2 screenPosition ) {
    // #if DEPTH_PACKING == 1
    //     return unpackRGBAToDepth( texture2D( tDepth, screenPosition ) );
    // #else
        return texture2D( tDepth, screenPosition ).x;
    // #endif
}

float getViewZ( const in float depth ) {
    // #if ORTHOGRAPHIC_CAMERA == 1
    //     return orthographicDepthToViewZ( depth, cameraNear, cameraFar );
    // #else
        return perspectiveDepthToViewZ( depth, cameraNear, cameraFar );
    // #endif
}

void main() {
    vec2 screenUV = gl_FragCoord.xy / resolution;

    // Depth
    float fragmentLinearEyeDepth = getViewZ( gl_FragCoord.z );
    float linearEyeDepth = getViewZ( getDepth ( screenUV ) );

    float diff = saturate( fragmentLinearEyeDepth - linearEyeDepth );

    vec4 waterDepth = vec4(mix(uDepthGradientDeep, uDepthGradientShallow, diff + 0.1));

    // Reflections
    float reflectionMask = texture2D( tWaterFoamTexture, ( vUv * 10.0 ) ).r;
    float reverseReflectionMask = 1.0 - reflectionMask;

    reflectionMask = sin(uTime) * reflectionMask;
    reverseReflectionMask = -sin(uTime) * reverseReflectionMask;

    float reflectionColor = reflectionMask + reverseReflectionMask + 0.2;

    // Distortion
    vec2 distortion = (texture2D( tWaterDUDVTexture, vUv * 10.0 ).rg * 2.0 - 1.0);

    // Ripple
    vec2 UV = vUv;
    UV.y += 1.0 * 0.01 * (sin(UV.x * 3.5 + uTime * 0.035) + sin(UV.x * 40.8 + uTime * 0.005) + sin(UV.x * 70.3 + uTime * 0.045)) / 3.0;
    UV.x += 1.0 * 0.12 * (sin(UV.y * 4.0 + uTime * 0.050) + sin(UV.y * 60.8 + uTime * 0.075) + sin(UV.y * 11.3 + uTime * 0.02)) / 3.0;
    UV.y += 1.0 * 0.12 * (sin(UV.x * 4.2 + uTime * 0.064) + sin(UV.x * 60.3 + uTime * 0.065) + sin(UV.x * 80.2 + uTime * 0.045)) / 3.0;

    vec2 waterRipple = texture2D( tWaterSeemlessTexture, UV * 4.0 - uTime * 0.02).rg;
    waterRipple += distortion;
    vec4 rippleColor = waterRipple.x > 0.64 ? vec4(vec3(1.0), 1.0) : vec4(vec3(0.0), 1.0);
    rippleColor.xyz *= abs(cnoise(vUv * 20.0)) * 0.45;

    // Shoreline Foam
    vec2 displacement = texture2D( tWaterFoamTexture, ( vUv * 10.0 ) - uTime * 0.05 ).rg;
    displacement = (( displacement * 2.0 ) - 1.0 ) * 0.25;

    float newDiff = diff + displacement.x;
    
    vec4 foamColor = newDiff < 0.075 ? vec4(1.0) : vec4(0.0);

    

    // Update Reflection
    vec4 reflectionColorVector = mix(vec4(0.1), vec4(displacement.x + 0.25), reflectionColor);

    vec4 finalColor = waterDepth + foamColor + rippleColor;

    // Shoreline
    finalColor = diff < 0.08 ? vec4(2.0) : finalColor;

    // gl_FragColor = finalColor;
    // gl_FragColor.a = 0.6;

    csm_FragColor = finalColor;
    csm_FragColor.a = 0.75;

    #include <tonemapping_fragment>
    #include <colorspace_fragment>
}