import { Plane, useGLTF, useTexture, useAnimations } from '@react-three/drei'
import { useEffect, useRef } from 'react'
import * as THREE from 'three'

export default function Landscape()
{
    // Preload for loading screen
    useGLTF.preload('./models/portfolio_world.glb')
    useTexture.preload('./bakes/bakedCampfireConstruction.jpg')
    useTexture.preload('./bakes/bakedLand.jpg')
    useTexture.preload('./bakes/bakedRocks1.jpg')
    useTexture.preload('./bakes/bakedRocks2.jpg')
    useTexture.preload('./bakes/bakedStatues.jpg')
    useTexture.preload('./bakes/bakedTrees1.jpg')
    useTexture.preload('./bakes/bakedTrees2.jpg')
    useTexture.preload('./bakes/bakedProjects.jpg')
    useTexture.preload('./bakes/bakedDockDetails.jpg')
    useTexture.preload('./bakes/bakedGrass.jpg')

    // Load World
    const { nodes, animations, scene } = useGLTF('./models/portfolio_world.glb')

    /**
     *  Baked Textures
     */ 
    // Load Textures
    const bakedCampfireConstructionTexture = useTexture('./bakes/bakedCampfireConstruction.jpg')
    const bakedGroundTexture = useTexture('./bakes/bakedLand.jpg')
    const bakedRocks1Texture = useTexture('./bakes/bakedRocks1.jpg')
    const bakedRocks2Texture = useTexture('./bakes/bakedRocks2.jpg')
    const bakedStatuesTexture = useTexture('./bakes/bakedStatues.jpg')
    const bakedTrees1Texture = useTexture('./bakes/bakedTrees1.jpg')
    const bakedTrees2Texture = useTexture('./bakes/bakedTrees2.jpg')
    const bakedProjectsTexture = useTexture('./bakes/bakedProjects.jpg')
    const bakedDockDetailsTexture = useTexture('./bakes/bakedDockDetails.jpg')
    const bakedGrassTexture = useTexture('./bakes/bakedGrass.jpg')

    // Adjust Textures
    bakedGroundTexture.flipY = false
    bakedCampfireConstructionTexture.flipY = false
    bakedRocks1Texture.flipY = false
    bakedRocks2Texture.flipY = false
    bakedStatuesTexture.flipY = false
    bakedTrees1Texture.flipY = false
    bakedTrees2Texture.flipY = false
    bakedProjectsTexture.flipY = false
    bakedDockDetailsTexture.flipY = false
    bakedGrassTexture.flipY = false

    bakedGroundTexture.colorSpace = THREE.SRGBColorSpace
    bakedCampfireConstructionTexture.colorSpace = THREE.SRGBColorSpace
    bakedRocks1Texture.colorSpace = THREE.SRGBColorSpace
    bakedRocks2Texture.colorSpace = THREE.SRGBColorSpace
    bakedStatuesTexture.colorSpace = THREE.SRGBColorSpace
    bakedTrees1Texture.colorSpace = THREE.SRGBColorSpace
    bakedTrees2Texture.colorSpace = THREE.SRGBColorSpace
    bakedProjectsTexture.colorSpace = THREE.SRGBColorSpace
    bakedDockDetailsTexture.colorSpace = THREE.SRGBColorSpace
    bakedGrassTexture.colorSpace = THREE.SRGBColorSpace

    /*
     *  Apply Materials
     */ 
    // Campfire + Construction
    const bakedCampfireConstructionMaterial = new THREE.MeshBasicMaterial({ map: bakedCampfireConstructionTexture })
    nodes.BakedConstruction.traverse((child) => { child.material = bakedCampfireConstructionMaterial })
    nodes.BakedBoombox.traverse((child) => { child.material = bakedCampfireConstructionMaterial })
    nodes.BakedCampfire.traverse((child) => { child.material = bakedCampfireConstructionMaterial })
    nodes.Fire.traverse((child) => { child.material = bakedCampfireConstructionMaterial })
    nodes.AboutMeText.traverse((child) => { child.material = bakedCampfireConstructionMaterial })

    // Me
    nodes.BakedMe.traverse((child) => { child.material = bakedCampfireConstructionMaterial })

    // Ground
    const bakedGroundMaterial = new THREE.MeshStandardMaterial({ map: bakedGroundTexture })
    // nodes.BakedLandscapeMesh.traverse((child) => { child.material = bakedGroundMaterial })
    nodes.BakedLandscapeMesh.material = bakedGroundMaterial
    nodes.BakedLandscapeMesh.traverse((child) => {
            if (child.isMesh)
            {
                child.castShadow = true
                child.receiveShadow = true
            }
        }
    )

    // Grass
    const bakedGrassMaterial = new THREE.MeshStandardMaterial({ map: bakedGrassTexture })
    nodes.Grass.traverse((child) => { child.material = bakedGrassMaterial })

    // Rocks
    const bakedRocks1Material = new THREE.MeshBasicMaterial({ map: bakedRocks1Texture })
    const bakedRocks2Material = new THREE.MeshBasicMaterial({ map: bakedRocks2Texture })
    nodes.BakedRocksMesh.traverse((child) => { child.material = bakedRocks1Material })
    nodes.BakedRocksMesh001.traverse((child) => { child.material = bakedRocks2Material })

    // Statues
    const bakedStatuesMaterial = new THREE.MeshBasicMaterial({ map: bakedStatuesTexture })
    nodes.VideoGames.traverse((child) => { child.material = bakedStatuesMaterial })
    nodes.Education.traverse((child) => { child.material = bakedStatuesMaterial })
    nodes.Work.traverse((child) => { child.material = bakedStatuesMaterial })
    nodes.Contact.traverse((child) => { child.material = bakedStatuesMaterial })

    // Projects
    const bakedProjectsMaterial = new THREE.MeshBasicMaterial({ map: bakedProjectsTexture })
    nodes.Projects.traverse((child) => { child.material = bakedProjectsMaterial })

    // Trees
    const bakedTrees1Material = new THREE.MeshBasicMaterial({ map: bakedTrees1Texture })
    const bakedTrees2Material = new THREE.MeshBasicMaterial({ map: bakedTrees2Texture })
    nodes.BakedTreesMesh.traverse((child) => child.material = bakedTrees1Material)
    nodes.BakedTreesMesh001.traverse((child) => child.material = bakedTrees2Material)

    // Dock + Details
    const bakedDockDetailsMaterial = new THREE.MeshBasicMaterial({ map: bakedDockDetailsTexture })
    nodes.Dock.traverse((child) => child.material = bakedDockDetailsMaterial)
    nodes.BakedBushes.traverse((child) => child.material = bakedDockDetailsMaterial)

    return <>
        {/* Campfire + Construction */}
        <primitive object={nodes.BakedConstruction} scale={0.2}/>
        <primitive object={nodes.BakedBoombox} scale={0.2}/>
        <primitive object={nodes.BakedCampfire} scale={0.2}/>
        <primitive object={nodes.Fire} scale={0.2}/>
        <primitive object={nodes.AboutMeText} scale={0.2}/>

        {/* Me */}
        <primitive object={nodes.BakedMe} scale={0.2}/>

        {/* Rocks */}
        <primitive object={nodes.BakedRocksMesh} scale={0.2}/>
        <primitive object={nodes.BakedRocksMesh001} scale={0.2}/>

        {/* Ground */}
        <primitive object={nodes.BakedLandscapeMesh} scale={0.2}/>

        {/* Grass */}
        <primitive object={nodes.Grass} scale={0.2}/>

        {/* Ground Shadows */}
        {/* <mesh 
            receiveShadow
            rotation-x={ -Math.PI * 0.5 }
            position-y={ -0.29 }
            position-x={ -5 }
            material={landscapeShadowMaterial}
        >
            <planeGeometry args={[20, 20]} />
        </mesh> */}

        {/* Statues */}
        <primitive object={nodes.VideoGames} scale={0.2}/>
        <primitive object={nodes.Education} scale={0.2}/>
        <primitive object={nodes.Work} scale={0.2}/>
        <primitive object={nodes.Contact} scale={0.2}/>
        <primitive object={nodes.Projects} scale={0.2}/>

        {/* Trees */}
        <primitive object={nodes.BakedTreesMesh} scale={0.2}/>
        <primitive object={nodes.BakedTreesMesh001} scale={0.2}/>

        {/* Docks */}
        <primitive object={nodes.Dock} scale={0.2}/>
        <primitive object={nodes.BakedBushes} scale={0.2}/>

    </>
}