import * as THREE from 'three'
import { useRef, useMemo, useEffect } from 'react'
import { useFrame } from '@react-three/fiber'
import { CuboidCollider, RigidBody } from '@react-three/rapier'

export default function Bubbles({ count, dummy = new THREE.Object3D(), position, visible = true, statuePosition = [0, 0, 0] })
{
    const mesh = useRef()

    const range = 0.4
    
    const particles = useMemo(() => 
    {
        const temp = []

        for (let i = 0; i < count; i++)
        {
            const randomX = Math.random() * range
            const randomY = Math.random() * range - 0.15
            const randomZ = Math.random() * range
            const randomSize = Math.random() * 0.03 + 0.01
            const randomLimit = Math.random()

            temp.push({ randomX, randomY, randomZ, randomSize, randomLimit, mx: 0, my: 0, mz: 0 })
        }
        
        return temp
    }, [count])

    useFrame((state) => 
    {
        particles.forEach((particle, index) => 
        {
            let { randomSize, randomX, randomY, randomZ, randomLimit } = particle
            
            particle.my += 0.0025
            particle.mz += 0.0009

            if(particle.my + position[1] + randomY > 0.75)
            {
                particle.my = 0
                particle.mz = 0
                randomX = Math.random() * range
                randomZ = Math.random() * range
            }

            dummy.position.set(randomX + position[0], -0.25 + particle.my + randomY + position[1], randomZ + position[2])
            dummy.scale.setScalar(particle.randomSize)
            dummy.rotation.set(randomX * 100, randomY * 50 + state.clock.elapsedTime, state.clock.elapsedTime)
            dummy.updateMatrix()
            mesh.current.setMatrixAt(index, dummy.matrix)
        })

        mesh.current.frustumCulled = false
        mesh.current.instanceMatrix.needsUpdate = true
    })

    return <>
        <instancedMesh ref={mesh} args={[null, null, count]} visible={visible}>
            <icosahedronGeometry args={[1]} />
            <meshStandardMaterial color="#cbefff" roughness={0} metalness={0} opacity={0.5} transparent={true} depthWrite={false}/>
        </instancedMesh>
    </>
}