import { create } from "zustand"
import { subscribeWithSelector } from "zustand/middleware"
import * as THREE from 'three'

export default create(subscribeWithSelector((set) =>
{
    return {
        cameraNear: 0.1,
        cameraFar: 200.0,
        focusedOnPlayer: true,
        playerPosition: new THREE.Vector3(),
        prevCameraTarget: new THREE.Vector3(0, 0, 0),
        prevCameraPosition: new THREE.Vector3(0, 0, 0),
        cameraTarget: new THREE.Vector3(0, 0, 0),
        cameraPosition: new THREE.Vector3(0, 0, 0)
    }
}))