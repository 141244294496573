import { Environment, OrbitControls, Sky, Sparkles } from '@react-three/drei'
import { Physics } from '@react-three/rapier'
import Lights from './Lights/Lights.jsx'
import MyEnvironment from './Environment/MyEnvironment.jsx'
import Player from './Player/Player.jsx'
import { Perf } from 'r3f-perf'
import { SMAA, Bloom, DepthOfField, EffectComposer, FXAA, Vignette } from '@react-three/postprocessing'
import { BlendFunction } from 'postprocessing'
import { Suspense, useEffect } from 'react'
import MainCamera from './Environment/Camera/MainCamera.jsx'

export default function Experience()
{
    return <>
        {/* Post Processing */}
        <Suspense>
            <EffectComposer depthBuffer={true}>
                <Bloom
                    mipmapBlur
                    intensity={ 0.1 }
                    luminanceThreshold={ 0.5 }
                />
                <Vignette
                    offset={ 0.3 }
                    darkness={ 0.5 }
                    blendFunction={ BlendFunction.NORMAL }
                />
                <DepthOfField focusDistance={0} focalLength={0.07} bokehScale={0.1} height={480} />
                <SMAA />
            </EffectComposer>
        </Suspense>

        {/* <Perf position="top-left" /> */}

        <fog args={[ 0xeaf0ff, 3, 20 ]} attach="fog" />
        <color args={[ '#241a1a' ]} attach="background" />

        {/* <OrbitControls makeDefault /> */}

        <Lights />

        <Suspense>
            <Physics colliders="hull">
                <MyEnvironment />
                <Player />
            </Physics>
        </Suspense>

        <MainCamera />

    </>
}