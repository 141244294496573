import DisplayInfo from "../DisplayInfo"

export default function Projects({ textProjectsVisible })
{
    return <>
        <DisplayInfo position={[-2.75, 0.25, -5.87]} rotation={[0, Math.PI * 0.55, 0]} textVisibility={textProjectsVisible} displayInfoClassName="projectsText">
            <div className="outerContainer scrollContainer2" id="scrollbar2">
                <a href="#" className="clickableLink" target="_blank">
                    <div className="project">
                        <div className="display">
                            <img src="./info/projects/project1.png" />
                            <div className="title">
                                <h2>3D Portfolio Website</h2>
                                <div className="languages">
                                    <div className="r3f">R3F</div>
                                    <div className="javascript">JS</div>
                                    <div className="reactjs">ReactJS</div>
                                    <div className="general-language">HTML</div>
                                    <div className="general-language">CSS</div>
                                    <div className="general-language">GLSL</div>
                                    <div className="general-language">Blender</div>
                                </div>
                            </div>
                        </div>
                        <div className="description">
                            <p>The site you're on right now! Fun and creative website to display all my work, personal projects, achievements, socials, and more! All 3D models and effects were built from scratch in Blender, Three.js, and GLSL by me.</p>
                        </div>
                    </div>
                </a>
                <a href="https://github.com/tommyyoun/Procedural-Cave-Generator" className="clickableLink" target="_blank">
                    <div className="project">
                        <div className="display">
                            <img src="./info/projects/project2.png" />
                            <div className="title">
                                <h2>Procedural 2D Cave Generator</h2>
                                <div className="languages">
                                    <div className="r3f">C#</div>
                                    <div className="javascript">Unity</div>
                                    <div className="general-language">Git</div>
                                </div>
                            </div>
                        </div>
                        <div className="description">
                            <p>Plug-in and ready to use procedural generator for a Unity 2D game that creates cave-like worlds using Cellular Automata and Marching Squares algorithms. Easily adjustable to fit your needs.</p>
                        </div>
                    </div>
                </a>
                <a href="https://github.com/tommyyoun/YouMedia" className="clickableLink" target="_blank">
                    <div className="project">
                        <div className="display" >
                        <img src="./info/projects/project3.png" />
                            <div className="title">
                                <h2>YouMedia</h2>
                                <div className="languages">
                                    <div className="r3f">PHP</div>
                                    <div className="javascript">MySQL</div>
                                    <div className="general-language">HTML</div>
                                    <div className="general-language">CSS</div>
                                </div>
                            </div>
                        </div>
                        <div className="description">
                            <p>A YouTube clone to practice skills working with databases. Includes user authentication with SHA256 encryption, user registration, video/image/audio file upload, commenting, liking, and disliking functions. Built with CodeIgniter4</p>
                        </div>
                    </div>
                </a>
            </div>
        </DisplayInfo>
    </>
}
