import { useHelper } from "@react-three/drei"
import { useRef } from "react"
import { DirectionalLightHelper } from "three"

export default function Lights()
{
    const directionLightRef = useRef()
    useHelper(directionLightRef, DirectionalLightHelper, 'red')

    return <>
        {/* <directionalLight
            ref={directionLightRef}
            castShadow
            color={"#ffffff"}
            position={ [ -1.6, 16, 17 ] }
            intensity={ 1.7 }
            shadow-mapSize={ [ 128, 128 ] }
            shadow-camera-near={ 1 }
            shadow-camera-far={ 100 }
            shadow-camera-top={ 100 }
            shadow-camera-right={ 100 }
            shadow-camera-bottom={ - 100 }
            shadow-camera-left={ - 100 }
        /> */}
        <ambientLight
            intensity={ 2.5 }
        />
        <pointLight 
            intensity={ 1 }
            position={[ -1.67, 15.66, 48.53 ]}
        />

        <directionalLight
            castShadow
            position={[ -1.6, 16, 17 ]}
            color={"#ffffff"}
            shadow-normalBias={0.04}
            intensity={3}
        />

        {/* Sun */}
        {/* <pointLight
            castShadow
            intensity={ 500 }
            position={[ -1.8, 14.33, 17.68 ]}
            color={"#FFCD96"}
        /> */}
    </>
}