import DisplayInfo from "../DisplayInfo"

export default function VideoGames({ textVideoGamesVisible })
{
    return <>
        <DisplayInfo position={[1.8, 0.23, -4.21]} rotation={[0, -Math.PI * 0.6, 0]} textVisibility={textVideoGamesVisible} displayInfoClassName="videoGamesText">
            <div className="outerContainer scrollContainer1" id="scrollbar3">
                <a href="https://github.com/tommyyoun/Leap" className="clickableLink" target="_blank">
                    <div className="project">
                        <div className="display">
                            <img src="./info/videogames/leap.png" />
                            <div className="title">
                                <h2>Leap</h2>
                                <div className="languages">
                                    <div className="r3f">C#</div>
                                    <div className="javascript">Unity</div>
                                    <div className="general-language">Git</div>
                                </div>
                            </div>
                        </div>
                        <div className="description">
                            <p>You (and maybe a friend) are at the bottom of a very large mountainous region. After growing tired of your boring life, you have decided to climb to the top and search for a better one. Legends do speak of a mysterious machine and a Frog God… what could all of this mean? Could the Frog God be real? Does he have a paradise in the heavens? Is the mysterious machine a lie? The only way to find out is to make it to the top and find out what lies in store!</p>
                        </div>
                    </div>
                </a>
                <a>
                    <div className="project">
                        <div className="display">
                            <img src="./info/videogames/COMING_SOON.jpg" />
                            <div className="title">
                                <h2>Unannounced Title</h2>
                                <div className="languages">
                                    <div className="r3f">Unity</div>
                                </div>
                            </div>
                        </div>
                        <div className="description">
                            <p>Currently working on a new sponsored indie title. Stay in touch for announcements!</p>
                        </div>
                    </div>
                </a>
            </div>
        </DisplayInfo>
    </>
}
