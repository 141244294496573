import DisplayInfo from "../DisplayInfo"

export default function Education({ textEducationVisible })
{
    return <>
        <DisplayInfo position={[-0.6, 0.55, -7.5]} rotation={[0, 0.2, 0]} textVisibility={textEducationVisible} displayInfoClassName="educationText">
            <div className="outerContainer">
                <h2>California State University, Los Angeles</h2>
                <h3>Bachelor's of Computer Science</h3>
                <p>Graduated May 2024, Summa Cum Laude</p>
            </div>
        </DisplayInfo>
        <DisplayInfo scale={0.01} position={[-1, 0.6, -7.5]} rotation={[0, 0.2, 0]} textVisibility={textEducationVisible} displayInfoClassName="educationText">
            <img src="./info/education/csula_logo.png" />
        </DisplayInfo>
    </>
}