import { CuboidCollider, RigidBody } from "@react-three/rapier";
import useSite from "../../../stores/useSite";
import { useKeyboardControls } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export default function CameraFocus({ position = [0, 0, 0], focusedTarget = [0, 0, 0], toggleTextVisibility = () => {} })
{
    const [ subscribeKeys, getKeys ] = useKeyboardControls()
    const site = useSite()

    const focusOnArea = () => 
    {
        site.cameraPosition.x = position[0]
        site.cameraPosition.y = position[1] + 0.5
        site.cameraPosition.z = position[2]

        site.cameraTarget.x = focusedTarget[0]
        site.cameraTarget.y = focusedTarget[1] + 0.5
        site.cameraTarget.z = focusedTarget[2]

        site.prevCameraTarget.x = site.playerPosition.x
        site.prevCameraTarget.y = site.playerPosition.y
        site.prevCameraTarget.z = site.playerPosition.z

        site.focusedOnPlayer = false

        toggleTextVisibility(true)
    }

    const unfocusOnArea = () => 
    {
        site.focusedOnPlayer = true

        toggleTextVisibility(false)
    }

    // Escape out of area
    useFrame((state, delta) => {
        const { escape } = getKeys()

        if(escape)
        {
            unfocusOnArea()
        }
    })

    return <>
        <RigidBody type="fixed">
            <CuboidCollider
                args={[0.2, 0.2, 0.2]}
                position={position}
                sensor
                onIntersectionEnter={focusOnArea}
                onIntersectionExit={unfocusOnArea}
            />
        </RigidBody>
    </>
}