import { RigidBody } from "@react-three/rapier"
import Landscape from "./Landscape.jsx"
import Water from "./Water.jsx"
import Bubbles from "../Particles/Bubbles.jsx"
import CameraFocus from "./Camera/CameraFocus.jsx"
import { Box, Center, Float, Sky, Sparkles, Text3D } from "@react-three/drei"
import DisplayInfo from "./Text/DisplayInfo.jsx"
import Fire from "../Particles/Fire.jsx"
import { useState } from "react"
import AboutMe from "./Text/Campfire/AboutMe.jsx"
import Projects from "./Text/Projects/Projects.jsx"
import WorkHistory from "./Text/WorkHistory/WorkHistory.jsx"
import Education from "./Text/Education.jsx/Education.jsx"
import VideoGames from "./Text/VideoGames/VideoGames.jsx"
import Contact from "./Text/Contact/Contact.jsx"
import { Suspense } from "react"

export default function MyEnvironment()
{
    const [ textCampfireVisible, setCampfireVisible ] = useState(() => false)
    const [ textProjectsVisible, setProjectsVisible ] = useState(() => false)
    const [ textWorkHistoryVisible, setWorkHistoryVisible ] = useState(() => false)
    const [ textEducationVisible, setEducationVisible ] = useState(() => false)
    const [ textVideoGamesVisible, setVideoGamesVisible ] = useState(() => false)
    const [ textContactVisible, setContactVisible ] = useState(() => false)

    return <>

        {/* World */}
        <Landscape />
        <Water />
        <Sky />

        {/* Instructions */}
        <Text3D font={'./fonts/Roboto_Bold.json'} position={[0.6, -0.01, 0.94]} rotation={[-Math.PI / 2, 0, 0]} scale={0.1}>
            W
            <meshStandardMaterial color="#2c2d0c"/>
        </Text3D>
        <Text3D font={'./fonts/Roboto_Bold.json'} position={[0.52, -0.01, 1.07]} rotation={[-Math.PI / 2, 0, 0]} scale={0.1}>
            A
            <meshStandardMaterial color="#2c2d0c"/>
        </Text3D>
        <Text3D font={'./fonts/Roboto_Bold.json'} position={[0.62, -0.01, 1.07]} rotation={[-Math.PI / 2, 0, 0]} scale={0.1}>
            S
            <meshStandardMaterial color="#2c2d0c"/>
        </Text3D>
        <Text3D font={'./fonts/Roboto_Bold.json'} position={[0.72, -0.01, 1.07]} rotation={[-Math.PI / 2, 0, 0]} scale={0.1}>
            D
            <meshStandardMaterial color="#2c2d0c"/>
        </Text3D>
        <Text3D font={'./fonts/Roboto_Bold.json'} position={[0.85, -0.01, 1.07]} rotation={[-Math.PI / 2, 0, 0]} scale={0.08}>
            to move!
            <meshStandardMaterial color="#008080"/>
        </Text3D>
        <Text3D font={'./fonts/Roboto_Bold.json'} position={[-0.4, -0.01, 0.7]} rotation={[-Math.PI / 2, 0, Math.PI * 0.65]} scale={0.08}>
            Row towards bubbles! -&gt;
            <meshStandardMaterial color="#008080"/>
        </Text3D>
        <Text3D font={'./fonts/Roboto_Bold.json'} position={[-0.35, -0.01, 0.5]}  rotation={[-Math.PI / 2, 0, Math.PI * 0.65]} scale={0.08}>
            (ESC to exit)
            <meshStandardMaterial color="#008080"/>
        </Text3D>

        {/* Fire */}
        <Fire count={20} position={[-2.9, -0.1, -1.22]} />

        {/* Bubbles */}
        {/* Campfire */}
        <Bubbles count={12} position={[-1.2, 0, -0.9]} />
        <CameraFocus position={[-1.15, 0, -0.85]} focusedTarget={[-2, 0, -0.9]} toggleTextVisibility={setCampfireVisible}/>
        <AboutMe textCampfireVisible={textCampfireVisible} />
        <Sparkles position={[-3, 0, -0.9]} />

        {/* Projects */}
        <Bubbles count={12} position={[-1.8, 0, -6.1]}/>
        <CameraFocus position={[-1.7, 0, -5.95]} focusedTarget={[-1.75, -0.01, -5.96]} toggleTextVisibility={setProjectsVisible}/>
        <Projects textProjectsVisible={textProjectsVisible} />

        {/* Work History */}
        <Bubbles count={12} position={[1.5, 0, -1.9]} />
        <CameraFocus position={[1.7, 0, -1.7]} focusedTarget={[1.8, -0.03, -1.715]} toggleTextVisibility={setWorkHistoryVisible}/>
        <WorkHistory textWorkHistoryVisible={textWorkHistoryVisible} />

        {/* Education */}
        <Bubbles count={12} position={[-0.6, 0, -6.4]} />
        <CameraFocus position={[-0.4, 0, -6.2]} focusedTarget={[-0.5, -0.04, -6.7]} toggleTextVisibility={setEducationVisible}/>
        <Education textEducationVisible={textEducationVisible} />

        {/* Video Games */}
        <Bubbles count={12} position={[0.8, 0, -4.5]}/>
        <CameraFocus position={[1.0, 0, -4.4]} focusedTarget={[1.1, -0.04, -4.4]} toggleTextVisibility={setVideoGamesVisible}/>
        <VideoGames textVideoGamesVisible={textVideoGamesVisible} />

        {/* Contact */}
        <Bubbles count={12} position={[-1, 0, -10.9]} />
        <CameraFocus position={[-0.75, 0, -10.8]} focusedTarget={[-0.86, -0.07, -11.8]} toggleTextVisibility={setContactVisible}/>
        <Contact textContactVisible={textContactVisible} />

        {/* Bottom Bounds */}
        <Suspense>
            <RigidBody 
                type="fixed" 
                friction={ 0.7 }
                rotation-x={ Math.PI * 0.5 } 
                position-y={ -0.162 }
                position-z={ -5.2 }
                scale={ [20, 20, 0.02] }
                includeInvisible={ true }
            >
                <mesh visible={ false } >
                    <boxGeometry />
                </mesh>
            </RigidBody>
        </Suspense>

        {/* Invisible Walls */}
        <RigidBody type="fixed" friction={ 0 } includeInvisible={ true }
            rotation-x={ Math.PI * 0.5 } position={[2.4, -0.162, -5.2]} scale={ [0.5, 20, 1] }
        ><Box visible={false}/></RigidBody>
        <RigidBody type="fixed" friction={ 0 } includeInvisible={ true }
            rotation-x={ Math.PI * 0.5 } position={[2, -0.162, 0.8]} scale={ [20, 0.5, 1] }
        ><Box visible={false}/></RigidBody>
        <RigidBody type="fixed" friction={ 0 } includeInvisible={ true }
            rotation-x={ Math.PI * 0.5 } position={[-1.65, -0.162, -0.4]} scale={ [0.7, 10, 1] }
        ><Box visible={false}/></RigidBody>
        <RigidBody type="fixed" friction={ 0 } includeInvisible={ true }
            rotation-x={ Math.PI * 0.5 } position={[-2.4, -0.162, -6]} scale={ [0.7, 10, 1] }
        ><Box visible={false}/></RigidBody>
        <RigidBody type="fixed" friction={ 0 } includeInvisible={ true }
            rotation-x={ Math.PI * 0.5 } position={[-2, -0.162, -11.5]} scale={ [0.7, 2, 1] }
        ><Box visible={false}/></RigidBody>
        <RigidBody type="fixed" friction={ 0 } includeInvisible={ true }
            rotation-x={ Math.PI * 0.5 } position={[-0.2, -0.162, -12.2]} scale={ [3, 1, 1] }
        ><Box visible={false}/></RigidBody>
        <RigidBody type="fixed" friction={ 0 } includeInvisible={ true }
            rotation-x={ Math.PI * 0.5 } position={[1.6, -0.162, -12.7]} scale={ [1, 4, 1] }
        ><Box visible={false}/></RigidBody>
        <RigidBody type="fixed" friction={ 0 } includeInvisible={ true }
            rotation-x={ Math.PI * 0.5 } position={[2.1, -0.162, -11.4]} scale={ [1, 4, 1] }
        ><Box visible={false}/></RigidBody>
        <RigidBody type="fixed" friction={ 0 } includeInvisible={ true }
            rotation-x={ Math.PI * 0.5 } position={[1.7, -0.162, -8.9]} scale={ [1, 1.5, 1] }
        ><Box visible={false}/></RigidBody>
        <RigidBody type="fixed" friction={ 0 } includeInvisible={ true }
            rotation-x={ Math.PI * 0.5 } position={[1.9, -0.162, -4.7]} scale={ [1, 1.5, 1] }
        ><Box visible={false}/></RigidBody>
        <RigidBody type="fixed" friction={ 0 } includeInvisible={ true }
            rotation-x={ Math.PI * 0.5 } position={[1.8, -0.162, -3.2]} scale={ [1.2, 1.25, 1] }
        ><Box visible={false}/></RigidBody>
        <RigidBody type="fixed" friction={ 0 } includeInvisible={ true }
            rotation-x={ Math.PI * 0.5 } position={[-0.5, -0.162, -7.4]} scale={ [1.2, 1, 1] }
        ><Box visible={false}/></RigidBody>
        <RigidBody type="fixed" friction={ 0 } includeInvisible={ true }
            rotation-x={ Math.PI * 0.5 } position={[-0.55, -0.162, -8.3]} scale={ [0.4, 1, 1] }
        ><Box visible={false}/></RigidBody>
        <RigidBody type="fixed" friction={ 0 } includeInvisible={ true }
            rotation-x={ Math.PI * 0.5 } position={[-0.7, -0.162, -9.4]} scale={ [0.7, 0.7, 1] }
        ><Box visible={false}/></RigidBody>
    </>
}