import { useProgress } from "@react-three/drei"
import { useEffect, useState } from "react"
import * as THREE from 'three'

export default function LoadingScreen() 
{
    const { active, progress, loaded, total } = useProgress()
    const [ start, setStart ] = useState(false)

    const listener = new THREE.AudioListener()
    const backgroundMusic = new THREE.Audio(listener)
    const backgroundMusicLoader = new THREE.AudioLoader()
    const ambientSound = new THREE.Audio(listener)
    const ambientSoundLoader = new THREE.AudioLoader()
    const birdSound = new THREE.Audio(listener)
    const birdSoundLoader = new THREE.AudioLoader()

    useEffect(() => {
        if (start) {
            backgroundMusicLoader.load( './sounds/website_tune_by_tim.wav', function(buffer) {
                backgroundMusic.setBuffer(buffer)
                backgroundMusic.setLoop(true)
                backgroundMusic.setVolume(0.5)
                backgroundMusic.play()
            })
            ambientSoundLoader.load( './sounds/river-water.mp3', function(buffer) {
                ambientSound.setBuffer(buffer)
                ambientSound.setLoop(true)
                ambientSound.setVolume(0.2)
                ambientSound.play()
            })
            birdSoundLoader.load( './sounds/forest_birds.mp3', function(buffer) {
                birdSound.setBuffer(buffer)
                birdSound.setLoop(true)
                birdSound.setVolume(0.1)
                birdSound.play()
            })
        }
    }, [start])

    return (
        <div className={`loadingScreen ${start ? "loadingScreen--started" : ""}`} >
            { loaded / total != 1
                ? <div className="loader-container">
                    <div className="loader"></div>
                    <div className="loader-progress">Loading {progress.toFixed(2)}%</div>
                </div>
                : <button className="loadingScreen_button" onClick={() => setStart(true)}>
                    Start
                </button>
            }
        </div>
    )
}