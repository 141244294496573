import DisplayInfo from "../DisplayInfo"

export default function Contact({ textContactVisible })
{
    return <>
        <DisplayInfo position={[-1.12, 0.5, -12]} rotation={[0, 0.25, 0]} textVisibility={textContactVisible} displayInfoClassName="contactText">
            <div className="outerContainer">
                <p>Thanks for visiting my website!</p>
                <p>Like it, have feedback, or just want to get in touch? Send me an email at <b>ty@tommyyoun.com</b> or follow me on my socials!</p>
                <div>
                    <a className="social" href="https://www.linkedin.com/in/tommy-youn/" target="_blank"><img src="./info/socials/linkedin.png" width="60"/></a>
                    <a className="social" href="https://github.com/tommyyoun" target="_blank"><img src="./info/socials/github.png" width="60"/></a>
                </div>
            </div>
        </DisplayInfo>
        <DisplayInfo position={[-0.4, 0.25, -12.2]} rotation={[0, -0.3, 0]} textVisibility={textContactVisible} displayInfoClassName="contactText">
            <div className="outerContainer">
                <p>This site is still in early development. More changes are coming soon!</p>
            </div>
        </DisplayInfo>
        <DisplayInfo position={[-0.4, 0.05, -12.2]} rotation={[0, -0.3, 0]} textVisibility={textContactVisible} displayInfoClassName="contactText">
            <div className="outerContainer">
                <h2>Special Thanks to:</h2>
                <p>BGM - Tim Sowa</p>
            </div>
        </DisplayInfo>
    </>
}