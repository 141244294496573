import DisplayInfo from "../DisplayInfo"

export default function WorkHistory({ textWorkHistoryVisible })
{
    return <>
        <DisplayInfo position={[2.5, 0.3, -1.57]} rotation={[0, -Math.PI * 0.6, 0]} textVisibility={textWorkHistoryVisible} displayInfoClassName="projectsText">
                <div className="outerContainer scrollContainer1" id="scrollbar1">
                    <a href="https://www.countyofsb.org/187/Public-Defender" className="clickableLink" target="_blank">
                        <div className="project">
                            <div className="display">
                                <img src="./info/work/sb.png" />
                                <div className="title">
                                    <h2>Santa Barbara 
                                        Public Defenders</h2>
                                    <h3>Lead Software Developer</h3>
                                </div>
                            </div>
                            <div className="description">
                                <h3>2023 - 2024, Los Angeles CA</h3>
                                <p>Designed and developed new internal management application to automate several processes in the MS 365 environment, leading a team of 11.</p>
                            </div>
                        </div>
                    </a>
                    <a href="https://www.calstatela.edu/ecst/cs" className="clickableLink" target="_blank">
                        <div className="project">
                            <div className="display">
                                <img src="./info/work/csula_logo.png" />
                                <div className="title">
                                    <h2>Cal State LA Computer Science Department</h2>
                                    <h3>Teaching Assistant & Researcher</h3>
                                </div>
                            </div>
                            <div className="description">
                                <h3>2022 - 2023, Los Angeles CA</h3>
                                <p>Lectured students on software engineering fundamentals in Java, Python, C, and Haskell. Researched and developed in drone technology.</p>
                            </div>
                        </div>
                    </a>
                    <a href="https://itd.alamedacountyca.gov/" className="clickableLink" target="_blank">
                        <div className="project">
                            <div className="display">
                                <img src="./info/work/ITD1.png" />
                                <div className="title">
                                    <h2>Alameda County ITD</h2>
                                    <h3>Software Developer Intern</h3>
                                </div>
                            </div>
                            <div className="description">
                                <h3>2019 - 2020, Oakland CA</h3>
                                <p>Implemented modern search engine page for the company, while also resolving several critical WebAIM accessibility issues across the entire website through new designs.</p>
                            </div>
                        </div>
                    </a>
                </div>
        </DisplayInfo>
    </>
}
