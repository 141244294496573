import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import { Loader, KeyboardControls } from '@react-three/drei'
import Experience from './Experience/Experience.jsx'
import LoadingScreen from './Experience/Loading/LoadingScreen.jsx'
import { Suspense } from 'react'

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <KeyboardControls
        map={[
            { name: 'forward', keys: [ 'ArrowUp', 'KeyW' ]},
            { name: 'backward', keys: [ 'ArrowDown', 'KeyS' ]},
            { name: 'leftward', keys: [ 'ArrowLeft', 'KeyA' ]},
            { name: 'rightward', keys: [ 'ArrowRight', 'KeyD' ]},
            { name: 'jump', keys: [ 'Space' ]},
            { name: 'escape', keys: [ 'Escape' ]},
        ]}
    >
        <Canvas
            shadows
            flat
            camera={ {
                fov: 45,
                near: 0.1,
                far: 200,
                position: [ 1.5, 2.8, 2.2 ]
            } }
            dpr={[1, 2]}
        >
            <Suspense fallback={null}>
                <Experience />
            </Suspense>
        </Canvas>
        <LoadingScreen />
    </KeyboardControls>
)