import { CuboidCollider, RigidBody, useRapier } from "@react-three/rapier"
import { useGLTF, useKeyboardControls, Float, useAnimations, CameraControls, Box } from "@react-three/drei"
import { useState, useEffect, useRef } from "react"
import { useFrame } from "@react-three/fiber"
import * as THREE from 'three'
import { useControls } from 'leva'
import Smoke from "../Particles/Smoke"
import useSite from "../../stores/useSite"

export default function Player()
{
    /**
     * Init
     */
    const [ subscribeKeys, getKeys ] = useKeyboardControls()
    const site = useSite()

    // Boat
    const body = useRef()
    const boat = useGLTF("./models/boat.glb")
    const animations = useAnimations(boat.animations, boat.scene)

    boat.scene.children.forEach((mesh) =>
    {
        mesh.castShadow = true
    })

    let bodyLoaded = false

    // Camera
    const [ smoothedCameraPosition ] = useState(() => new THREE.Vector3(1.5, 2.8, 2.2))
    const [ smoothedCameraTarget ] = useState(() => new THREE.Vector3())

    // Smoke
    const [ smokePosition ] = useState(() => new THREE.Vector3(0, 0, 0))
    let [ smokeVisible ]  = useState(() => false)

    /**
     * Debug
     */
    // Controls
    // const { impulseFactor, torqueFactor } = useControls('Ship Physics', {
    //     impulseFactor: { value: 0.025, min: 0.005, max: 0.15, step: 0.001 },
    //     torqueFactor: { value: 0.025, min: 0.005, max: 0.15, step: 0.001 }
    // })

    // Camera
    // const { cameraPositionDebug } = useControls('Camera', {
    //     cameraPositionDebug: [ 1.5, 2.8, 2.2 ]
    // })

    useEffect(() =>
    {
        body.current.restrictRotations(false, true, false)
        bodyLoaded = true
        
        boat.nodes.Boat.traverse((child) => 
            child.castShadow = child.receiveShadow = true
        )
        
        // Animation
        animations.actions["Paddle1Action"].reset().play()
        animations.actions["Paddle2Action"].reset().play()
        
        return () =>
        {
            action.fadeOut(0.5)
        }
    }, [])

    // Vector Normalize Helper
    const normalize = (vector) =>
    {
        const magnitude = Math.sqrt(vector.x * vector.x 
            + vector.y * vector.y 
            + vector.z * vector.z)

        let adjustedX = vector.x / magnitude
        let adjustedY = vector.y / magnitude
        let adjustedZ = vector.z / magnitude

        if(isNaN(adjustedX)) adjustedX = 0
        if(isNaN(adjustedY)) adjustedY = 0
        if(isNaN(adjustedZ)) adjustedZ = 0

        return { x: adjustedX , y: adjustedY, z: adjustedZ }
    }

    // Vector Magnitude Helper
    const getMagnitude = ({ x, y, z }) => Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2))

    useFrame((state, delta) =>
    {
        if(bodyLoaded)
        {
            /**
             * Check Controls
             */
            const { forward, backward, leftward, rightward } = getKeys()

            let impulse = { x: 0, y: 0, z: 0 }
            let impulseDirection = { x: 0, y: 0, z: 0 }
            const torque = { x: 0, y: 0, z: 0 }

            const impulseStrength = 0.455 * delta
            const torqueStrength = 0 * delta

            if(forward)
            {
                impulseDirection.z -= 1
                impulse.z -= impulseStrength
            }

            if(rightward)
            {
                impulseDirection.x += 1
                impulse.x += impulseStrength
                torque.z -= torqueStrength
            }

            if(backward)
            {
                impulseDirection.z += 1
                impulse.z += impulseStrength
            }

            if(leftward)
            {
                impulseDirection.x -= 1
                impulse.x -= impulseStrength
                torque.x -= torqueStrength
            }

            impulseDirection = normalize(impulseDirection)

            const normalizedImpulse = {
                x: impulseDirection.x * Math.abs(impulse.x),
                y: impulseDirection.y * Math.abs(impulse.y),
                z: impulseDirection.z * Math.abs(impulse.z)
            }
            
            body.current.applyImpulse(normalizedImpulse)
        }

        /**
         * Update Camera
         */
        let bodyCurrent = 
            body.current 
            ?? 
            { 
                x: 0, y: 0, z: 0, 
                translation: () => {
                    return { x: bodyCurrent.x, y: bodyCurrent.y, z: bodyCurrent.z }
                }, 
                isNotLoaded: true 
            }

        if (bodyCurrent.isNotLoaded && body.current) bodyCurrent = body.current

        if (site.focusedOnPlayer)
        {
            const bodyCurrentTemp = bodyCurrent.translation()
            site.playerPosition = new THREE.Vector3(bodyCurrentTemp.x, bodyCurrentTemp.y, bodyCurrentTemp.z)
            site.cameraTarget = site.playerPosition

            site.cameraPosition.x = bodyCurrentTemp.x + 1.5
            site.cameraPosition.y = bodyCurrentTemp.y + 2.8
            site.cameraPosition.z = bodyCurrentTemp.z + 2.2
        }
    })

    return <>
        <RigidBody
            ref={ body }
            canSleep={ false }
            friction={ 0.7 }
            linearDamping={ 0.7 }
            angularDamping={ 0.5 }
            scale={[ 0.125, 0.135, 0.135 ]}
            colliders={ false }
            mass={ 10 }
            position={[0.65, 1.5, 0.25]}
        >
            <primitive 
                castShadow
                receiveShadow
                object={boat.scene} 
                rotation-y={ Math.PI }
                position-y={ -0.7 }
                position-z={ -0.5 }
                scale={ 0.5 }
            />
            <CuboidCollider args={[ 1.0, 1.7, 2.0 ]} position={[0, 0, -0.5]}/>
        
        </RigidBody>
        {/* <Smoke 
            count={ 1 } 
            position={ smokePosition }
            visible={ true }
        /> */}
    </>
}