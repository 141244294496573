import { CameraControls } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import useSite from "../../../stores/useSite";

export default function MainCamera()
{
    const cameraControlsRef = useRef()
    const site = useSite()

    useEffect(() => {
        cameraControlsRef.current.mouseButtons.wheel = 0
    }, [])

    useFrame((state, delta) =>
    {
        cameraControlsRef.current.lerpLookAt(
            // Look at from
            state.camera.position.x,
            state.camera.position.y,
            state.camera.position.z,
            site.prevCameraTarget.x,
            site.prevCameraTarget.y,
            site.prevCameraTarget.z,

            // Look at
            site.cameraPosition.x,
            site.cameraPosition.y,
            site.cameraPosition.z,
            site.cameraTarget.x,
            site.cameraTarget.y,
            site.cameraTarget.z,

            // Interpolation factor
            1,

            // Enable Transition
            true
        )
    })

    return <>
        <CameraControls
            ref={cameraControlsRef}
            enabled
            minDistance={0.0}
        />
    </>
}