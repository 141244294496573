import DisplayInfo from "../DisplayInfo"

export default function AboutMe({ textCampfireVisible })
{
    return <>
        <DisplayInfo position={[-2.6, 0.75, -1.08]} rotation={[0, Math.PI * 0.4, 0]} textVisibility={textCampfireVisible} displayInfoClassName="campfireText">
            <div className="outerContainer">
                <p>Hey there!</p>
                <p>My name is Tommy Youn and I'm recent Computer Science graduate from California State University, Los Angeles.</p> 
                <p>I'm a software and game developer. I'm currently looking for employment and would love to create fun experiences for everyone.</p>
                <p>Hope you have fun exploring my website!</p>
            </div>
        </DisplayInfo>
        <DisplayInfo scale={0.004} position={[-2.2, 0.8, -0.85]} rotation={[0, Math.PI * 0.4, 0]} textVisibility={textCampfireVisible} displayInfoClassName="campfireText">
            <div className="picture">
                <img src="./info/me.jpg" />
            </div>
        </DisplayInfo>
    </>
    
}
