import { Html } from "@react-three/drei";
import { DoubleSide } from "three";

export default function DisplayInfo({ children, position = [0, 0, 0], rotation = [0, 0, 0], scale = 0.025, textVisibility, displayInfoClassName })
{
    return <>
        <Html
            as="div"
            transform
            position={position}
            rotation={rotation}
            scale={scale}
            style={{
                transition: 'all 0.8s',
                opacity: textVisibility ? 1 : 0
            }}
            material={
                <meshPhysicalMaterial
                    side={DoubleSide}
                    opacity={0.1}
                />
            }
        >
            <div className={displayInfoClassName} style={{ transform: 'scale(2)' }}>
                { children }
            </div>
        </Html>
    </>
}