import { Float, MeshReflectorMaterial, shaderMaterial, useDepthBuffer } from "@react-three/drei"
import { extend, useFrame, useThree } from "@react-three/fiber"
import { useState, useRef, useEffect, useMemo } from "react"
import * as THREE from 'three'
import waterVertexShader from '/Shaders/Water/vertex.glsl'
import waterFragmentShader from '/Shaders/Water/fragment.glsl'
import useSite from "../../stores/useSite"
import CustomShaderMaterial from 'three-custom-shader-material'

export default function Water()
{
    const water = useRef()
    const waterMaterial = useRef()
    const depthBuffer = useDepthBuffer({ size: 1024 })

    const cameraNear = useSite((state) => state.cameraNear)
    const cameraFar = useSite((state) => state.cameraFar)

    var waterDUDVMapTexture = new THREE.TextureLoader().load("./water/waterDudvMap.png")
    waterDUDVMapTexture.wrapS = waterDUDVMapTexture.wrapT = THREE.RepeatWrapping

    var waterFoamTexture = new THREE.TextureLoader().load("./water/waterFoamTexture.png")
    waterFoamTexture.wrapS = waterFoamTexture.wrapT = THREE.RepeatWrapping

    var waterSeemlessTexture = new THREE.TextureLoader().load("./water/seemlessWaterTexture.jpg")
    waterSeemlessTexture.wrapS = waterSeemlessTexture.wrapT = THREE.RepeatWrapping

    // const WaterMaterial = shaderMaterial(
    //     {
    //         uTime: 0,
    //         tDepth: { value: null },
    //         tWaterFoamTexture: null,
    //         tWaterSeemlessTexture: null,
    //         uDepthGradientShallow: new THREE.Vector4(0.394, 0.491, 0.986, 1),
    //         uDepthGradientDeep: new THREE.Vector4(0.098, 0.265, 0.308, 1),
    //         uDepthMaxDistance: 0.5,
    //         resolution: new THREE.Vector2(),
    //         cameraNear: 0,
    //         cameraFar: 0
    //     },
    //     waterVertexShader,
    //     waterFragmentShader
    // )
    
    // extend({ WaterMaterial })

    const waterUniforms = useMemo(() => ({
        uTime: { value: 0.0 },
        tDepth: { value: null },
        tWaterDUDVTexture: { value: null },
        tWaterFoamTexture: { value: null },
        tWaterSeemlessTexture: { value: null },
        uDepthGradientShallow: { value: new THREE.Vector4(0.69, 0.88, 0.69, 1) },
        uDepthGradientDeep: { value: new THREE.Vector4(0.37, 0.62, 0.63, 1) },
        uDepthMaxDistance: { value: 0.5 },
        resolution: { value: new THREE.Vector2() },
        cameraNear: { value: 0.0 },
        cameraFar: { value: 0.0 }
    }), [])

    useEffect(() =>
    {
        // Reflection + Refraction
        // waterMaterial.current.uniforms.tFrameBuffer = frameBufferTexture

        // Textures
        waterUniforms.tWaterFoamTexture.value = waterFoamTexture
        waterUniforms.tWaterDUDVTexture.value = waterDUDVMapTexture
        waterUniforms.tWaterSeemlessTexture.value = waterSeemlessTexture

        // Depth Buffer
        waterUniforms.tDepth.value = depthBuffer
        waterUniforms.cameraNear.value = cameraNear
        waterUniforms.cameraFar.value = cameraFar

        // waterUniforms.defines = {
        //     DEPTH_PACKING: 0,
        //     ORTHOGRAPHIC_CAMERA: 0
        // }

        // Handle Resize
        waterUniforms.resolution.value.set(
            window.innerWidth * devicePixelRatio,
            window.innerHeight * devicePixelRatio
        )

    }, [depthBuffer, window.innerHeight, window.innerWidth])

    useFrame((state) =>
    {
        waterUniforms.uTime.value = state.clock.elapsedTime
    })

    return <>
        {/* Custom Water */}
        <mesh 
            ref={ water } 
            depthBuffer={ depthBuffer } 
            rotation-x={ -Math.PI * 0.5 }
            position={[0, 0, -5.2]}
        >
            <planeGeometry 
                args={[ 20, 20, 4, 4 ]}
            />
            {/* <waterMaterial 
                ref={ waterMaterial }
                key={ WaterMaterial.key }
                transparent={ true }
            /> */}
            <CustomShaderMaterial
                ref={ waterMaterial }
                baseMaterial={ THREE.MeshPhysicalMaterial }
                vertexShader={ waterVertexShader }
                fragmentShader={ waterFragmentShader }
                uniforms={
                    waterUniforms
                }
                color={0x68c3c0}
                roughness={0}
                metalness={0}
                transparent={true}
                thickness={0.9}
            />
        </mesh>

    </>
}