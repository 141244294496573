import * as THREE from 'three'
import { useRef, useMemo, useEffect } from 'react'
import { useFrame } from '@react-three/fiber'
import { CuboidCollider, RigidBody } from '@react-three/rapier'

export default function Fire({ count, dummy = new THREE.Object3D(), position, visible = true })
{
    const mesh = useRef()

    const range = 0.1
    
    const particles = useMemo(() => 
    {
        const temp = []

        for (let i = 0; i < count; i++)
        {
            const randomX = Math.random() * range - (range / 2.0)
            const randomY = Math.random() * range * 2.0
            const randomZ = Math.random() * range - (range / 2.0)
            const randomSize = Math.random() * 0.05 + 0.04
            const randomLimit = Math.random()

            temp.push({ randomX, randomY, randomZ, randomSize, randomLimit, mx: 0, my: 0, mz: 0 })
        }
        
        return temp
    }, [count])

    useFrame((state) => 
    {
        particles.forEach((particle, index) => 
        {
            let { randomSize, randomX, randomY, randomZ, randomLimit } = particle
            
            particle.my += 0.0025

            if(particle.my + randomY + position[1] > 0.06 + (0.15 - Math.abs(randomX)) + (0.15 - Math.abs(randomZ)))
            {
                particle.my = 0
                randomX = Math.random() * range - (range / 2.0)
                randomZ = Math.random() * range - (range / 2.0)
            }

            dummy.position.set(randomX + position[0], particle.my + randomY + position[1], randomZ + position[2])
            dummy.scale.setScalar(randomSize / (1 + particle.my * 9))
            dummy.rotation.set(randomX * 100, randomY * 50 + state.clock.elapsedTime, state.clock.elapsedTime)
            dummy.updateMatrix()
            mesh.current.setMatrixAt(index, dummy.matrix)
        })

        mesh.current.frustumCulled = false
        mesh.current.instanceMatrix.needsUpdate = true
    })

    return <>
        <instancedMesh ref={mesh} args={[null, null, count]} visible={visible}>
            <boxGeometry args={[1]} />
            <meshStandardMaterial color="#ff9a00" roughness={1} metalness={0}/>
        </instancedMesh>
    </>
}